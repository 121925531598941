import {
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField, Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import FormDialog from "../../Layout/FormDialog";
import {isValidEmail, successToast, warningToast} from "../../Generales/Tools";
import axios from 'axios';
import {Search as SearchIcon} from "@mui/icons-material";
import ReplayIcon from '@mui/icons-material/Replay';
import TableUsers from "./TableUsers";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Usuario = () => {

    const source = axios.CancelToken.source();
    const usuId = localStorage.getItem('UsuId');
    const history = useNavigate();
    const Dispvo = localStorage.getItem('Dispvo');
    const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'
    const [usuarios, setUsuarios] = useState([]);
    const [tipo, setTipoList] = useState([]);
    const [espera, setEspera] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [filtro, setFiltro] = useState('')
    const [cargando, setCargando] = useState(true)
    const [modalAbierto, setModalAbierto] = useState(false);
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
    const [inputType, setInputType] = useState('password');

    const [nuevoUsuario, setNuevoUsuario] = useState({
        falta: '',
        id: '',
        idDisp: '',
        ip: '',
        puesto: '',
        status: 1,
        tipo: 0,
        name: '',
        user: '',
        pass: '',
        email: ''
    })
    const [nameError, setNameError] = useState('')
    const [userError, setUserError] = useState('')
    const [passError, setPassError] = useState('')
    const [emailError, setEmailError] = useState('')

    useEffect(() => {
        listarUsuarios()
        listarTipo()
        clearCache()
    }, [])

    useEffect(() => {
        listarUsuarios()
    }, [filtro])

    function clearCache() {
        let data = qs.stringify({
            idDispositivo: auxDispV
        });

        let url = "usuario-redis-clear";

        function respuesta(response) {
            if (response.respuesta !== -1) {

            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    function listarUsuarios() {
        let data = qs.stringify({
            Usuario: usuId,
            Cadena: 'sist',
            idDispositivo: '45vf2d1vdf21d2f'
        });

        let url = "usuario-list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                setUsuarios(response)
                setCargando(false)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    function listarTipo() {
        let data = qs.stringify({
            Usuario: usuId,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        let url = "usuario-tipo-list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                setTipoList(response)
                setCargando(false)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    function crearNuevo() {
        setCargando(true)
        let data = qs.stringify({
            Tipo: nuevoUsuario.tipo,
            Nombre: nuevoUsuario.name,
            Usuario: nuevoUsuario.user,
            Pass: nuevoUsuario.pass,
            Email: nuevoUsuario.email,
            idDispositivo: auxDispV
        });

        let url = "usuario-nuevo";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El Usuario ' + nuevoUsuario.name + ' se registro correctamente.')
                setModalAbierto(false)
                listarUsuarios()
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    function editarUsuario() {
        setCargando(true)
        let data = qs.stringify({
            Llave: selectedRow.Id,
            Estatus: nuevoUsuario.status,
            Tipo: nuevoUsuario.tipo,
            Nombre: nuevoUsuario.name,
            Usuario: nuevoUsuario.user,
            Pass: nuevoUsuario.pass,
            Email: nuevoUsuario.email,
            idDispositivo: auxDispV
        });

        let url = "usuario-editar";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El Usuario ' + nuevoUsuario.name + ' se edito correctamente.')
                setModalAbierto(false)
                setIsUpdate(false)
                listarUsuarios()
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    function editarEstatus() {
        let data = qs.stringify({
            idUsuario: selectedRow.Id,
            Status: nuevoUsuario.status,
            idDispositivo: auxDispV
        });

        let url = "usuario-editar-status";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El estatus de ' + nuevoUsuario.name + ' se edito correctamente.')
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
    }

    const Buscar = () => {
        let aux = usuarios.filter((item) => item.Nombre.toLowerCase().includes(filtro.toLowerCase()))
        setUsuarios(aux)
    }

    const Refrescar = () => {
        setFiltro('')
    }

    const handleUsuario = (event) => {
        setNameError('')
        setUserError('')
        setPassError('')
        setEmailError('')
        setBtnDeshabilitado(false)
        setNuevoUsuario({...nuevoUsuario, [event.target.name]: event.target.value});
    }

    const abrirModalNuevo = (event) => {
        event.preventDefault()
        setModalAbierto(true)
        setNuevoUsuario({
            falta: '',
            id: '',
            idDisp: '',
            ip: '',
            puesto: '',
            status: 1,
            tipo: 0,
            name: '',
            user: '',
            pass: '',
            email: ''
        })
        setNameError('')
        setUserError('')
        setPassError('')
        setIsUpdate(false)
        setEmailError('')
    }

    const handleEditar = (id) => {
        let row = usuarios.find(x => x.Id === id)
        setSelectedRow(row)
        setNuevoUsuario({
            name: row.Nombre, user: row.Usuario, pass: row.Pass, email: row.Email, tipo: row.Tipo, status: row.Status
        })
        setNameError('')
        setUserError('')
        setPassError('')
        setEmailError('')
        setIsUpdate(true)
        setModalAbierto(true)
    }

    const handleEditarEstatus = (id) => {
        let row = usuarios.find(x => x.Id === id)
        setSelectedRow(row)
        setNuevoUsuario({
            status: row.Status === 1 ? 0 : 1
        })
        if(row.Status === 1){
            row.Status = 0
        }else{
            row.Status = 1
        }
        editarEstatus()
        let posicion = usuarios.findIndex((element) => element.Id === id)
        usuarios.splice(posicion, 1, row)
    }

    const handleNuevoUsuario = (event) => {
        event.preventDefault();
        if (nuevoUsuario.name === '' || nuevoUsuario.user === '' || nuevoUsuario.pass === '' || nuevoUsuario.email === '') {
            setBtnDeshabilitado(true)
            setCargando(true)
            if (nuevoUsuario.name === '') {
                setNameError('Debe ingresar un nombre')
                setCargando(false)
            }
            if (nuevoUsuario.user === '') {
                setUserError('Debe ingresar un usuario')
                setCargando(false)
            }
            if (nuevoUsuario.pass === '') {
                setPassError('Debe ingresar una contraseña')
                setCargando(false)
            }
            if (nuevoUsuario.email === '') {
                setEmailError('Debe ingresar un email')
                setCargando(false)
            } else {
                if (!isValidEmail(nuevoUsuario.email)) {
                    setEmailError('Debe ingresar un email')
                    setCargando(false)
                }
            }
        } else {
            setBtnDeshabilitado(true)
            setCargando(true)
            if (isUpdate) {
                editarUsuario()
            } else {
                crearNuevo()
            }
        }
    }

    const handleFiltro = (e) => {
        let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setFiltro(e.target.value)
        }
    }

    const changeVisibility = () => {
        setInputType(inputType === 'password' ? 'text' : 'password')
    }

    return (<>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                        <Grid item>
                            <TextField variant={'outlined'} name={'Buscar'} label={'Buscar por Nombre'} size={'small'}
                                       value={filtro}
                                       onChange={handleFiltro}/>
                        </Grid>
                        <Grid item>
                        <Tooltip title={'Buscar'}>
                            <IconButton color="primary" size="small" component="span" onClick={Buscar}>
                                <SearchIcon/>
                            </IconButton>
                        </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Refrescar tabla'}>
                            <IconButton color="primary" size="small" component="span" onClick={Refrescar}>
                                <ReplayIcon/>
                            </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            {cargando ? <LinearProgress/> :
                <TableUsers abrirModalNuevo={abrirModalNuevo} usuarios={usuarios} handleEditar={handleEditar}
                            handleEditarEstatus={handleEditarEstatus}/>}
        </Grid>
        <Grid item xs={12}>
            <FormDialog tamanio={'sm'}
                        titulo={isUpdate ? ("Editar Usuario " + selectedRow.Nombre) : 'Nuevo Usuario'}
                        modalAbierto={modalAbierto}
                        setModalAbierto={setModalAbierto}
                        btnDeshabilitado={btnDeshabilitado}
                        cargando={espera}
                        guardarDatos={handleNuevoUsuario}
                        setCargando={setEspera}
                        bgTitle={false}
            >
                <form noValidate autoComplete={'off'}>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="tipo">Tipo de Usuario</InputLabel>
                                <Select
                                    labelId="tipo" id="tipo" name="tipo"
                                    value={nuevoUsuario.tipo} label="Tipo" onChange={handleUsuario}
                                >
                                    {tipo.length !== 0 ? tipo.map((element, index) =>
                                            <MenuItem key={index} value={element.Id}>{element.Nom}</MenuItem>) :
                                        <MenuItem value={-1}>Sin Datos</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="name" name="name" label="Nombre" variant="standard"
                                       value={nuevoUsuario.name}
                                       error={nameError !== '' ? true : false} helperText={nameError}
                                       fullWidth
                                       onChange={handleUsuario}
                                       type={'text'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField onChange={handleUsuario} id="email" name="email" label="Email"
                                       value={nuevoUsuario.email}
                                       error={emailError !== '' ? true : false} helperText={emailError}
                                       variant="standard" type={'email'}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField onChange={handleUsuario} id="user" name="user"
                                       label="Usuario"
                                       variant="standard" value={nuevoUsuario.user}
                                       error={userError !== '' ? true : false}
                                       helperText={userError}
                                       type={'text'} fullWidth/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="pass" name="pass" label="Contraseña" variant="standard"
                                       value={nuevoUsuario.pass}
                                       type={inputType} error={passError !== '' ? true : false} helperText={passError}
                                       fullWidth onChange={handleUsuario}
                                       InputProps={isUpdate ? {
                                           endAdornment: (
                                               <InputAdornment position="start">
                                                   {inputType === 'password' ?
                                                       <VisibilityOffIcon onClick={changeVisibility}/> :
                                                       <VisibilityIcon onClick={changeVisibility}/>}
                                               </InputAdornment>
                                           ),
                                       } : null}
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormDialog>
        </Grid>
    </>);
};
export default Usuario;