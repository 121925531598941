import React, { useEffect, useRef } from "react";

export default function useAnalyser(source) {
  const canvasRef = useRef(null);
  const audioRef = useRef(null);
  const ctxRef = useRef(null);
  const analizerRef = useRef(null);
  const signalRef = useRef(false);
  
  useEffect(() => {
    const { current: canvas } = canvasRef;
    const { current: audio } = audioRef;
    if (!canvas || !audio) return;
    const ctx = canvas.getContext("2d");
    ctx?.fillRect(0, 0, canvas.width, canvas.height);
    ctxRef.current = ctx;
  }, [canvasRef, audioRef]);
  const startDrawing = (analyser) => {
    //if (audioRef?.current?.paused) return;
    if (signalRef.current) return;
    const { current: ctx } = ctxRef;
    if (!canvasRef.current || !analyser || !ctx) return;
    console.log("drawing");
    const WIDTH = canvasRef.current.width;
    const HEIGHT = canvasRef.current.height;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const barWidth = (WIDTH / bufferLength) * 3;
    let x = 0;
    requestAnimationFrame(() => startDrawing(analyser));
    analyser.getByteFrequencyData(dataArray);
    ctx.fillStyle = "#111111";
    ctx.fillRect(0, 0, WIDTH, HEIGHT);
    // all the magic
    dataArray.forEach((decibel, index) => {
      const c = index / bufferLength;
      const r = decibel + 25 * c;
      const g = 250 * c;
      const b = 250;
      ctx.fillStyle = `rgb(${r},${g},${b})`;
      ctx.fillRect(x, HEIGHT - decibel * 0.5, barWidth, decibel);
      x += barWidth + 1;
    });
  };

  const setConfig = async () => {
    signalRef.current = false;
    const { current: audio } = audioRef;
    if (!audio || analizerRef.current) {
      startDrawing(analizerRef.current);
      return;
    }
    const blob = await fetch(source, { headers: { "Content-Type": "application/octet-stream", crossOrigin:""}, mode: 'no-cors'})
    .then((r) => r.blob())
    //const url = URL.createObjectURL(blob);
    //audio.src=url;
    //audio.srcObject=blob
    audio.play();
    const context = new AudioContext();
    const src = context.createMediaElementSource(audio);
    const analyser = context.createAnalyser();
    src.connect(analyser);
    analyser.connect(context.destination);
    analyser.fftSize = 256;
    analizerRef.current = analyser;
    startDrawing(analyser);
  };
  const canvas = (<canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />);
  const player = (
    <audio
      onPause={() => {
        setTimeout(() => {
          signalRef.current = true;
        }, 1000);
      }}
      onPlay={setConfig}
      ref={audioRef}
      src={source}
      crossOrigin="anonymous"
      controls
    />
  );
  return [player, canvas];
}