import DrawerAppBar from "./Appbar";
import {useContext} from "react";
import {AuthContext} from "../Generales/AuthContext";
import {Grid, Toolbar} from "@mui/material";

const Container = (props) => {
    const {usuario} = useContext(AuthContext);

    return (<Grid container>
        <Grid item xs={12}>
            {usuario.length !== 0 ? 
                <>
                    <DrawerAppBar colorTema={props.colorTema} setColorTema ={props.setColorTema}/>  
                    <Toolbar/> 
                </>
            : null}
        </Grid>
        <Grid item xs={12}>
            {props.children}
        </Grid>
    </Grid>);
};

export default Container;
