import {Box, } from "@mui/material";
import FormDialogInfo from '../../Layout/FormDialogInfo'
//import {useState} from "react";
const ImgAlbum = ({modalAbierto, setModalAbierto, registro}) => {
  return (
    < FormDialogInfo tamanio={'md'}
    titulo={ `${registro.Titulo}`}
    modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto}
    //guardarDatos={guardarDatos}
    //btnDeshabilitado={btnDeshabilitado}
    //cargando={cargando}
    //setCargando={setCargando}
    bgTitle={false}
    >
        <Box style={{width:25+'rem'}} >
   <img src={registro.Img} style={{width:100+'%'}}  />
   </Box>
</FormDialogInfo>
  )
}

export default ImgAlbum