import React from 'react'
import useAnalyser from "../hooks/useAnalyser2";
const Reproductor = ({link}) => {
    
  const [player, bars] = useAnalyser(link  );
  return (
<div style={{ fontFamily: "sans-serif", textAlign: "center"}}>
        {player}
        <div style={{  display: "flex",background:"black"}}>
          {bars}
          </div>
        </div>  
  )
}

export default Reproductor