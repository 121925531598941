import {Box,} from "@mui/material";
import FormDialogInfo from '../../Layout/FormDialogInfo'
import { Player } from 'video-react';
import Reproductor from './Reproductor'
import ShowAudio from "./ShowAudio";
const MediaTema = ({modalAbierto, setModalAbierto, registro}) => { 
  let auxLink= "https://titanio-records.sfo3.digitaloceanspaces.com/album/audio/10/221230/7b3ada05-201b-4e55-8daf-d876f2003f4d.mp3"
  //let auxLink2= "./10 - Desde Que Despierto.mp3"
  return (
  < FormDialogInfo tamanio={'md'} bgTitle={false}
    titulo={ `${registro.AlbumNom} - ${registro.Num} ${registro.Titulo}`}
    modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}        
  >
    <Box style={{width:25+'rem'}} >  
      
      {/*   <ShowAudio source={auxLink}/>   */} 
      { registro.Links.map((liga )=>{
        if (liga.Tipo==="AUDIO") 
        { 
          return(<Reproductor link={liga?.Link}/>) 
           /*  return(
          <section>            
             
             <audio controls autoplay id="miAudio">
              <source src={liga?.Link} type={`audio/x-m4a`} /> 
              <source src={liga?.Link} type={ "audio/mp4"} />     
              <source src={liga?.Link } type={ "audio/mpeg"} />     
              <source src={liga?.Link } type={ "audio/wav"} />     
              <source src={liga?.Link } type={ "audio/ogg"} />      
              <source src={liga?.Link } type={ "audio/mp3"} />                                               
            </audio> 
          </section>
          ) */
        }
        else
        { if (liga.Tipo==="VIDEO") 
          {
            return(          
            <Player src={liga?.Link} 
              playsInline poster=""              
            />            
            )
          }
        }
      }) 
      }      
    </Box>
  </FormDialogInfo>
  )
}

export default MediaTema