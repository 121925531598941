import React,{useEffect,useState} from 'react'
import FormDialogInfo from '../../Layout/FormDialogInfo'
import axios from 'axios';
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../../funciones/ClienteAxios';
import { Card, Typography, Box, CardContent,Grid, LinearProgress } from '@mui/material';
const ModalGenero = ({modalAbierto, setModalAbierto, registro}) => {
    const history = useNavigate();
    const source = axios.CancelToken.source();
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const usuId =localStorage.getItem('UsuId') ; 
    const [listGenero, setListGenero] = useState([])
    const [espera, setEspera] = useState([])
    useEffect(() => {
       
        
        llenarListGenero()
      return () => {
      
      }
    }, [ ])
    
    const llenarListGenBase = () => {      
        let data = qs.stringify({
            Llave:1,
            idDispositivo:auxDispV
        });
        let url = "album-genero-base";
        function respuesta(aux) {
            if (aux[0].Id !== -1) 
            {   //console.log(JSON.parse(aux));
            }
        }
        
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    const llenarListGenero = () => {      
        let data = qs.stringify({
            Usuario:usuId,
            Album:registro.Id,
            idDispositivo:auxDispV
        });
        let url = "album-genero-list";
        function respuesta(aux) {
            if (aux[0].Id !== -1) 
            {   
                setListGenero(aux)
            }
        }
        
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
    };

    return (
    <FormDialogInfo tamanio={'md'} bgTitle={false}
        titulo={ `Generos de ${registro.Titulo}`}
        modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
    >
       <Grid container>
        {!espera?
            listGenero.length!==0?
                listGenero.map((genero)=>{
                return <Grid item><Card sx={{borderRadius: "7px"}}>
                
                    <Typography variant="body1"  
                        sx={{marginLeft:.5+'rem',marginRight:.5+'rem',
                            marginTop:.2+'rem',marginBottom:.2+'rem'}} 
                    >
                        {genero.Nom}
                    </Typography>
                
                </Card>  </Grid>})
            :<Typography>Sin generos</Typography>
        :<Box width={10+'rem'} ><LinearProgress/></Box>}
      </Grid>
    </FormDialogInfo>
    )
}

export default ModalGenero