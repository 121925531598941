import {Button, Card, CardContent, Grid, IconButton, LinearProgress, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import NoImage from '../../Layout/Images/NoImage.png'
import qs from "qs";
import {apiFDataHeader, ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import FormDialog from "../../Layout/FormDialog";
import {successToast, warningToast} from "../../Generales/Tools";
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {Search as SearchIcon} from "@mui/icons-material";
import TableInterprete from "./TableInterprete";
import ReplayIcon from '@mui/icons-material/Replay';

const Interprete = () => {

        const source = axios.CancelToken.source();
        const usuId = localStorage.getItem('UsuId');
        const history = useNavigate();
        const Dispvo = localStorage.getItem('Dispvo');
        const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'
        const [interpretes, setInterpretes] = useState([]);
        const [espera, setEspera] = useState(false)
        const [isUpdate, setIsUpdate] = useState(false)
        const [selectedRow, setSelectedRow] = useState([])
        const [files, setFiles] = useState()
        const [selectedImage, setSelectedImage] = useState('')
        const [filtro, setFiltro] = useState('')
        const [cargando, setCargando] = useState(true)
        const [modalAbierto, setModalAbierto] = useState(false);
        const [modalImagenAbierto, setModalImagenAbierto] = useState(false);
        const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);

        const [nuevoInterprete, setNuevoInterprete] = useState({img: '', name: '', represent: '', tel: '', email: ''})
        const [nameError, setNameError] = useState('')
        const [representError, setRepresentError] = useState('')
        const [telError, setTelError] = useState('')
        const [emailError, setEmailError] = useState('')
        const [progresoEnvio, setProgresoEnvio] = useState(0);
        const [nombreArchivo, setNombreArchivo] = useState("")
        useEffect(() => {
            listarInterpretes()
           // clearCache()
        }, [])

        useEffect(() => {
            listarInterpretes()
        }, [filtro])

        function clearCache() {
            let data = qs.stringify({
                idDispositivo: auxDispV
            });

            let url = "interprete-clear-redis";

            function respuesta(response) {
                if (response.respuesta !== -1) {

                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
        }

        function listarInterpretes() {
            let data = qs.stringify({
                Usuario: usuId, idDispositivo: auxDispV
            });

            let url = "interprete-list";

            function respuesta(response) {
                if (response[0].Id !== -1) {
                    setInterpretes(response)
                    setCargando(false)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
        }

        function crearNuevo() {
            setCargando(true)
            let data = qs.stringify({
                Usuario: usuId,
                Nombre: nuevoInterprete.name,
                Representa: nuevoInterprete.represent,
                Tel: nuevoInterprete.tel,
                Email: nuevoInterprete.email,
                idDispositivo: auxDispV
            });

            let url = "interprete-new";

            function respuesta(response) {
                if (response.respuesta === 1) {
                    successToast('El interprete ' + nuevoInterprete.name + ' se registro correctamente.')
                    setModalAbierto(false)
                    listarInterpretes()
                } else {
                    warningToast('Ocurrio un error.')
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
        }

        function subirImagen() {

            let bodyFormData = new FormData();
            bodyFormData.append('Llave', selectedRow.Id)
            bodyFormData.append('Usuario', usuId)
            bodyFormData.append('idDispositivo', '45vf2d1vdf21d2f')
            bodyFormData.append('multi-files', files)

            let url = "interprete-envio-file";
            const headers = {
                "size": files.size, "tipo": "SPACE",
                "clase": 'INTERPRETE',
                "llave": selectedRow.Id,
            }

            function respuesta(auxiliar) {
                if (auxiliar.success === true) {
                    setProgresoEnvio(0)
                    successToast('Se envio correctamente el archivo ' + nombreArchivo)
                    setModalImagenAbierto(false)
                    listarInterpretes()
                }
            }

            apiFDataHeader(bodyFormData, url, respuesta, ErrorGeneral, setEspera, history, source, headers, setProgresoEnvio)
        }

        function editarInterprete() {
            setCargando(true)
            let data = qs.stringify({
                Interprete: selectedRow.Id,
                Usuario: usuId,
                Status: 1,
                Nombre: nuevoInterprete.name,
                Representa: nuevoInterprete.represent,
                Tel: nuevoInterprete.tel,
                Email: nuevoInterprete.email,
                idDispositivo: auxDispV
            });

            let url = "interprete-edit";

            function respuesta(response) {
                if (response.respuesta === 1) {
                    successToast('El interprete ' + nuevoInterprete.name + ' se edito correctamente.')
                    setModalAbierto(false)
                    setIsUpdate(false)
                    listarInterpretes()
                } else {
                    warningToast('Ocurrio un error.')
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history);
        }

        const Buscar = () => {
            let aux = interpretes.filter((item) =>
                item.Nom.toLowerCase().includes(filtro.toLowerCase())
            )
            setInterpretes(aux)
        }

    const Refrescar = () => {
        setFiltro('')
    }

        const handleInterprete = (event) => {
            setNameError('')
            setRepresentError('')
            setTelError('')
            setEmailError('')
            setBtnDeshabilitado(false)
            setNuevoInterprete({...nuevoInterprete, [event.target.name]: event.target.value});
        }

        const abrirModalNuevo = (event) => {
            event.preventDefault()
            setModalAbierto(true)
            setNuevoInterprete({img: '', name: '', represent: '', tel: '', email: ''})
            setNameError('')
            setRepresentError('')
            setTelError('')
            setIsUpdate(false)
            setEmailError('')
        }

        const abrirModalImagen = (id) => {
            let row = interpretes.find(x => x.Id === id)
            setSelectedImage(row.Img)
            setSelectedRow(row)
            setModalImagenAbierto(true)
        }

        const handleEditar = (id) => {
            let row = interpretes.find(x => x.Id === id)
            setSelectedRow(row)
            setNuevoInterprete({
                img: row.Img,
                name: row.Nom,
                represent: row.Representa,
                tel: row.Tel,
                email: row.Email
            })
            setNameError('')
            setRepresentError('')
            setTelError('')
            setEmailError('')
            setIsUpdate(true)
            setModalAbierto(true)
        }

        const handleNuevoInterprete = (event) => {
            event.preventDefault();
            if (nuevoInterprete.name === '' || nuevoInterprete.represent === '' || nuevoInterprete.tel === '' || nuevoInterprete.email === '') {
                setBtnDeshabilitado(true)
                setCargando(true)
                if (nuevoInterprete.name === '') {
                    setNameError('Debe ingresar un interprete')
                    setCargando(false)
                }
                if (nuevoInterprete.represent === '') {
                    setRepresentError('Debe ingresar un representante')
                    setCargando(false)
                }
                if (nuevoInterprete.tel === '') {
                    setTelError('Debe ingresar un teléfono')
                    setCargando(false)
                }
                if (nuevoInterprete.email === '') {
                    setEmailError('Debe ingresar un email')
                    setCargando(false)
                }
            } else {
                setBtnDeshabilitado(true)
                setCargando(true)
                if (isUpdate) {
                    editarInterprete()
                } else {
                    crearNuevo()
                }
            }
        }

        const handleImage = (event) => {
            let img = event.target.files[0]
            let auxCode = uuidv4();
            const regex = /([a-zA-Z0-9\s_.\-():])+(.jpg|.jpeg|png)$/

            if (img && img.name.toLowerCase().match(regex)) {
                let auxNombre = auxCode + img.name.slice(img.name.lastIndexOf("."), img.name.length);
                let myNewFile = new File([img], auxNombre, {type: img.type});
                setFiles(myNewFile)
                setSelectedImage(URL.createObjectURL(img))
                setNombreArchivo(img.name)
            } else {
                warningToast(`Formato de archivo incorrecto, seleccione
                 una imagen tipo jpg,jpeg o png.`)
            }
        }

        const handleFiltro = (e) => {
            let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
            let expMenos = new RegExp('-');
            let expMas = new RegExp('[+]');
            let expCadena = new RegExp('[A-Za-z0-9]');
            if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
                    !expMenos.test(e.target.value) && !expMas.test(e.target.value))
                || e.target.value === ' ' || e.target.value === '') {
                setFiltro(e.target.value)
            }
        }

        return (<>
            <Grid item xs={12}>
                <Card >
                    <CardContent>
                        <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                            <Grid item>
                                <TextField variant={'outlined'} name={'Buscar'} label={'Buscar por Nombre'} size={'small'}
                                           value={filtro}
                                           onChange={handleFiltro}/>
                            </Grid>
                            <Grid item>
                                <IconButton color="primary" size="small" component="span" onClick={Buscar}>
                                    <SearchIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton color="primary" size="small" component="span" onClick={Refrescar}>
                                    <ReplayIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {cargando ? <LinearProgress/> :
                    <TableInterprete abrirModalNuevo={abrirModalNuevo} interpretes={interpretes} handleEditar={handleEditar}
                                     abrirModalImagen={abrirModalImagen}/>
                }
            </Grid>
            <Grid item xs={12}>
                <FormDialog tamanio={'md'}
                            titulo={("Editar imagen " + selectedRow.Nom)}
                            modalAbierto={modalImagenAbierto}
                            setModalAbierto={setModalImagenAbierto}
                            btnDeshabilitado={btnDeshabilitado}
                            cargando={espera}
                            guardarDatos={subirImagen}
                            setCargando={setEspera}
                            bgTitle={false}
                            btnTitulo={"Enviar"}
                >
                    <form noValidate autoComplete={'off'}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={12} textAlign={'center'}>
                                <div>
                                    <img src={selectedImage === '' ? NoImage : selectedImage} width={350}
                                         height={300}/>
                                </div>
                                <input type="file" name="file" onChange={handleImage} hidden id={`cargarArchivo`}
                                       accept={"image/jpg,image/jpeg,image/png"} multiple/>
                                <label htmlFor={`cargarArchivo`}>
                                    <Button color="primary" variant="contained" size="small" component="span"
                                            style={{fontSize: 12}}
                                    >
                                        Seleccionar imagen
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </form>
                </FormDialog>
            </Grid>
            <Grid item xs={12}>
                <FormDialog tamanio={isUpdate ? 'md' : 'sm'}
                            titulo={isUpdate ? ("Editar Interprete " + selectedRow.Nom) : 'Nuevo Interprete'}
                            modalAbierto={modalAbierto}
                            setModalAbierto={setModalAbierto}
                            btnDeshabilitado={btnDeshabilitado}
                            cargando={espera}
                            guardarDatos={handleNuevoInterprete}
                            setCargando={setEspera}
                            bgTitle={false}
                >
                    <form noValidate autoComplete={'off'}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            {isUpdate ? <Grid item xs={6} textAlign={'center'}>
                                <img src={nuevoInterprete.img === '' ? NoImage : nuevoInterprete.img}
                                     width={300} height={300}/>
                            </Grid> : null}
                            <Grid item xs={isUpdate ? 6 : 12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField id="name" name="name" label="Nombre" variant="standard"
                                                   value={nuevoInterprete.name}
                                                   error={nameError !== '' ? true : false} helperText={nameError}
                                                   fullWidth
                                                   onChange={handleInterprete}
                                                   type={'text'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField onChange={handleInterprete} id="represent" name="represent"
                                                   label="Representante"
                                                   variant="standard" value={nuevoInterprete.represent}
                                                   error={representError !== '' ? true : false}
                                                   helperText={representError}
                                                   type={'text'} fullWidth/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField onChange={handleInterprete} id="tel" name="tel" label="Teléfono"
                                                   value={nuevoInterprete.tel}
                                                   error={telError !== '' ? true : false} helperText={telError}
                                                   variant="standard" type={'tel'} fullWidth/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField onChange={handleInterprete} id="email" name="email" label="Email"
                                                   value={nuevoInterprete.email}
                                                   error={emailError !== '' ? true : false} helperText={emailError}
                                                   variant="standard" type={'email'}
                                                   fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormDialog>
            </Grid>
        </>);
    }
;
export default Interprete;
