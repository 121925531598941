import React from 'react';
import PropTypes from 'prop-types';
import {Box,Typography,Tab,Tabs,AppBar,} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TablaContrato from './TablaContrato';
import TablaTema from './TablaTema';
import TablaAlbum from './TablaAlbum';
import TablaContratoMusic from './TablaContratoMusic';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles()((theme) => {
  return {
  root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  };
  });


export default function BusquedaTabs({datos}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <div className={classes.root}>
    <Box ml={1} >
      <AppBar position="static" color="transparent"  >
        <Tabs 
          value={value} onChange={handleChange}
          aria-label="simple tabs example" indicatorColor="secondary"
          textColor="secondary" centered                        
        >
          
          <Tab label={ `Contrato (${datos.length !==0 &&datos?.contrato_usr.length && datos.contrato_usr[0].Id !== - 1 ?datos.contrato_usr.length:0})`} 
            {...a11yProps(0)} />
          <Tab label={ `Contrato Musical (${datos.length !==0 && datos?.contrato_music.length && datos.contrato_music[0].Id !== - 1 ?datos.contrato_music.length:0})`} 
            {...a11yProps(1)} />
          <Tab label={ `Album (${datos.length !==0 &&datos?.albums.length && datos.albums[0].Id !== - 1 ?datos.albums.length:0})`} 
            {...a11yProps(2)} />
          <Tab label={ `Tema (${datos.length !==0 && datos?.temas.length && datos.temas[0].Id !== - 1 ?datos.temas.length:0})`} 
            {...a11yProps(3)} />
          
        </Tabs>
      </AppBar>
    </Box>      
    <TabPanel value={value} index={0}>
      {datos.length !==0  ? <TablaContrato datos={datos} /> :null }
    </TabPanel>            
    <TabPanel value={value} index={1} >
      {datos.length !==0 ? <TablaContratoMusic datos={datos} /> :null }
    </TabPanel>      
    <TabPanel value={value} index={2} >
      {datos.length !==0 ? <TablaAlbum datos={datos}/> :null }      
    </TabPanel>      
    <TabPanel value={value} index={3} >      
      {datos.length !==0 ? <TablaTema datos={datos}/> :null }      
    </TabPanel>        
  </div>
  );
}