import React,{useState,useEffect} from 'react'
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {TextField,Stack ,MenuItem,FormControl,Select,InputLabel,
  Box }from '@mui/material';
import FormDialog from '../../Layout/FormDialog'
import {llamadaApiToken,ErrorGeneral,llamadaApiCarga } from '../../funciones/ClienteAxios';
import {printConsole} from '../../funciones/ConsolaLog'
import moment from 'moment';
import {toast} from 'react-toastify';
const NuevoContrato = ({modalAbierto, setModalAbierto, registro,Buscar}) => {
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const history = useNavigate();
  const usuId =localStorage.getItem('UsuId') ; 
  const hoy=moment(new Date()).format('YYYY-MM-DD')
  const source = axios.CancelToken.source();            
  const [espera,setEspera]= useState(false);
  const [listTipo,setListTipo]= useState([])  
  const [listSello,setListSello]= useState([])  
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)  
  const [contadorToast,setContadorToast]= useState(0)  
  const [contrato,setContrato]= useState({Tipo:'', Sello:'',
    FIni:hoy, FFin:hoy,
    FFirma:hoy+"T09:00",
    Representa:'',
    Descripcion:'',
    Observacion:'',})
  const{Tipo, Sello, FIni, FFin, FFirma, Representa, Descripcion,Observacion}=contrato
  useEffect(() => {

    const llenarListTipo = () => {      
      let data = qs.stringify({
        usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "/contrato-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListTipo(aux);                
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    const llenarListSello = () => {      
      let data = qs.stringify({
        Usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "/contrato-sello";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListSello(aux);   
            //printConsole(aux)             
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    llenarListTipo()
    llenarListSello()

    return () => {
      source.cancel();
    }

  }, [])
  
  const llamadaApi = () => {                   
    
    let data = qs.stringify({
        Usuario:usuId, Tipo:Tipo, Sello:Sello.length!==0?Sello:0,
        FIni:`${FIni}T00:00:00`,
        FFin:`${FFin}T23:59:59`,
        FFirma:`${FFirma}:00`,
        Representa:Representa,
        Descripcion:Descripcion,
        Observacion:Observacion,
        idDispositivo: auxDispV,
    });
    
    let url = "contrato-new";
    function respuesta(auxiliar){            
      if ( auxiliar) 
      {                 
        Buscar()
        let mensaje="Se creo correctamente el registro"
        //setTipoAdvertencia("success")      
        //setMensaje(mensaje);
        //setAviso(true)     
        toast.success(`${mensaje}`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-env`
        })  

        setModalAbierto(false)    
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
  };  

  const onChangeDate=(e)=>{
    if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined 
      
    ) {
      if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
      {
        if (e.target.name==="FIni" ) {
          setContrato({...contrato,[e.target.name] : e.target.value,
            FFirma:e.target.value+"T09:00",
          })    
        }else{
          setContrato({...contrato,[e.target.name] : e.target.value})  
        }
        
      }      
    } 
     
    printConsole(e.target.value)    
    printConsole(e.target.value.length)
  }

  const onChange=(e)=>{
    
    if (e.target.name==="Tipo") 
    {
      setContrato({...contrato,[e.target.name] : e.target.value,Sello : ""})
    }
    else
    {
      setContrato({...contrato,[e.target.name] : e.target.value})
    }
    
  }

  const selectTipo= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
    <InputLabel id="demo-select-small">Tipo</InputLabel>
    <Select
      labelId="demo-select-small" id="demo-select-small" name="Tipo"
      value={Tipo} label="Tipo" onChange={onChange}
    >      
      {listTipo.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Alias}</MenuItem>)}
    </Select>
  </FormControl>

  const selectSello= <FormControl sx={{  width: "17rem"}} size="small">
    <InputLabel id="demo-select-small">Sello</InputLabel>
    <Select
      labelId="demo-select-small" id="demo-select-small" name="Sello"
      value={Sello} label="Sello" onChange={onChange}
    >      
      {listSello.map((Sello)=><MenuItem value={Sello.Id}>{Sello.Nom}</MenuItem>)}
    </Select>
  </FormControl>

  const verificaNulos = (campo,descripcion)=>{
    let respuesta =false;              
    if( (typeof campo !== 'number' && typeof campo === 'string' 
        && (campo.trim() === '' || campo.trim() === null) )
    ) 
    { let mensaje='No se lleno el campo '+descripcion      
      toast.warning(`${mensaje}`,{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `error-env ${contadorToast}`
      })  
      setContadorToast(contadorToast+1)
      respuesta =true
    }    
    return(respuesta);
  }

  const guardarDatos=()=>{    
    if ( !verificaNulos(Tipo,"Tipo") && (Tipo===1 && !verificaNulos(Sello,"Sello") || Tipo!==1 )  )
      
    {      
      if (Representa.length>=3 ||  !verificaNulos("","Representa")) 
      {
        if (Descripcion.length>=3  ||  !verificaNulos("","Descripción")) {         
         llamadaApi()
        }
      }
    }
    
  }

  return (
  <FormDialog
    titulo="Nuevo Contrato" modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tamanio="md" btnDeshabilitado={false} cargando={espera}
    setCargando={setEspera}
  >
    <Stack component="form" noValidate spacing={2} sx={{width:"28rem"}}>
      <Box>
      {listTipo.length!==0 ? selectTipo:null}
      {listSello.length!==0 && Tipo===1? selectSello:null}
      </Box>
      <TextField
        label="Representa" id="representa-campo"
        value={Representa} name="Representa"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />
      <Box >
      <TextField
        id="date-Ini" label="Fecha Inicio" type="date" size='small'
        value={FIni} sx={{ width: 140,marginRight:.5+'rem' }} name="FIni"
        InputLabelProps={{ shrink: true }} onChange={onChangeDate}
      />
      <TextField
        id="date-Fin" label="Fecha Final" type="date" size='small'        
        value={FFin} sx={{ width: 140 }} name="FFin"
        InputLabelProps={{ shrink: true }} onChange={onChangeDate}
      />
      </Box>
      <TextField
        id="datetime-Firma" label="Fecha Firma" size='small'
        type="datetime-local" name="FFirma" value={FFirma} 
        onChange={onChangeDate} sx={{ width: 290 }} 
        InputLabelProps={{ shrink: true, }}
      />
      <TextField
        id="outlined-multiline-static" label="Descripción"
        multiline rows={3} name="Descripcion" onChange={onChange}
        value={Descripcion} size='small'
      />
      <TextField
        id="outlined-multiline-static" label="Observación"
        multiline  value={Observacion} size='small'
        rows={3} name="Observacion" onChange={onChange}
        
      />
    </Stack>
  </FormDialog>
  )
}

export default NuevoContrato