import {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../Generales/AuthContext";
import {GeneralContext} from "../Generales/GeneralContext";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness3 from '@mui/icons-material/Brightness3';

export default function DrawerAppBar(props) {

    const {window} = props;
    const navigate = useNavigate()
    const drawerWidth = 240;
    //const navItems = ['Home', 'Events', 'Team','Busqueda'];
    const navItems = ['busqueda', 'interprete','contrato','sello','album'/* ,'usuario' */];
    const [open, setOpen] = useState(false);    
    const {guardarUsuario} = useContext(AuthContext);
    const {generales, setGenerales} = useContext(GeneralContext);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const cerrarSesion = (event) => {
        event.preventDefault();
        localStorage.clear();
        guardarUsuario([])
        setGenerales([])
        navigate("/");
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <List>
                {navItems.map((item) => (
                    <div key={item}>
                        <ListItem disablePadding>
                            <ListItemButton sx={{textAlign: 'center'}} component={Link}
                                            to={`/${item}`}
                            >
                                <ListItemText primary={item.toUpperCase()}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                    </div>
                ))}
                <ListItem disablePadding>
                    <ListItemButton onClick={cerrarSesion}
                                    sx={{textAlign: 'center', display: {xs: 'block', sm: 'none'}}}
                    >
                        <ListItemText primary={'Cerrar Sesión'}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    const cambiarTema = (auxColor) => {
        props.setColorTema(!auxColor)
        localStorage.setItem('ColorTheme', !auxColor);
    }
    const btnTema=<Tooltip title={!props.colorTema ? `Cambiar a Modo Oscuro` : `Cambiar a Modo Claro`}>
    <IconButton color="inherit" onClick={() => cambiarTema(props.colorTema)}
    >
        {props.colorTema ? <BrightnessLow/> : <Brightness3/>}
    </IconButton>
</Tooltip>
    return (
        <>
            <AppBar component="nav" color="primary" enableColorOnDark>
                <Toolbar>
                    <IconButton aria-label="open drawer"
                                color="inherit" edge="start" sx={{mr: 2}}
                                onClick={handleDrawerToggle}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6"
                                sx={{
                                    flexGrow: 1, marginLeft: '.5rem', textDecoration: "none",
                                    boxShadow: "none", color: 'white'
                                }}
                    >
                        {generales.titulo ? generales.titulo : ""}
                    </Typography>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        {btnTema}
                        <Button color="inherit" onClick={cerrarSesion} >Cerrar Sesión</Button>
                    </Box>
                    <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                        {btnTema}                 
                    </Box>
                </Toolbar>

            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container} variant="temporary"
                    open={open} onClose={handleDrawerToggle}
                    ModalProps={{keepMounted: true}}

                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: (theme) => theme.palette.primary.light,
                            color: 'white'
                        }
                    }
                    }
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}