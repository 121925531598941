import React,{useState,useEffect} from 'react'
import {Box,Card,CardContent,IconButton,LinearProgress,TextField,
  FormControl,InputLabel,Select,MenuItem, Grid,Tooltip 
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import {Search as SearchIcon} from '@mui/icons-material/';
import axios from 'axios';
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../../funciones/ClienteAxios';
import { printConsole } from '../../funciones/ConsolaLog';
import TablaContrato from './TablaContrato';
import moment from 'moment';
import TablaArchivos from './TablaArchivos';
const useStyles = makeStyles()((theme) => {
  return {
      root: {
          minWidth: 275,
      },    
      title: {
          fontSize: 28,
      },
      title2: {
          fontSize: 18,
      },
      subTitle2: {
          fontSize: 12,
      },
      pos: {
      marginBottom: 12,
      },
      addButton: {
          marginRight: theme.spacing(1),       
      },
      altura2: {
          //height: 70,
          //width:'100%',
          margin:0,
          padding:0,
          // width:275
      }, 
  };
});

const Contrato = () => {

  const { classes } = useStyles();
  const source = axios.CancelToken.source();
  const history = useNavigate();
  const hoy=moment(new Date()).format('YYYY-MM-DD')
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const usuId =localStorage.getItem('UsuId') ; 
  const [filtro, setFiltro] =  useState({registro:"ANDRES",tipo:1,FIni:hoy, 
    FFin:hoy});
  const [espera, setEspera] = useState(false)
  const [esperaArch, setEsperaArch] = useState(false)
  const [listTipo,setListTipo]= useState([]) 
  const [datos, setDatos] = useState([])
  const [contrato, setContrato] = useState([])
  const [listArchivos, setListArchivos] = useState([])
  const {registro,tipo,FIni,FFin}=filtro;
  useEffect(() => {
    const llenarListTipo = () => {      
      let data = qs.stringify({
        usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "/contrato-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListTipo(aux);                
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    llenarListTipo()
    return () => {
      source.cancel();
    }
  }, [ ])

  useEffect(() => {
   
    if (contrato.length!==0) 
    {
      llenarListFile()  
    }
  
    return () => {
      source.cancel();
    }
  }, [ contrato])

  const llenarListFile = () => {      
    let data = qs.stringify({
      Usuario:usuId,
      Contrato:contrato.Id,
      idDispositivo:auxDispV
    });
    let url = "contrato-file-list";
    function respuesta(aux) {
      if (aux[0].Id !== -1) 
      { setListArchivos(aux);                  
      }
    }
          
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaArch, history, source);
  }; 

  const llenaListBusq = () => {
    setDatos([])
    
    setListArchivos([])
    let data = qs.stringify({
        usuario:usuId,        
        tipo:tipo,
        fini:`${FIni}T00:00:00`,
        ffin:`${FFin}T23:59:59`,
        idDispositivo: auxDispV
    });
    let url = "/contrato-intervalo";

    function respuesta(auxiliar) 
    { 
      if (auxiliar[0].Id !== -1) 
      {                 
        setDatos(auxiliar)
      }
      else
      {
        setDatos([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };

  const onChange = e =>
  { let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
      !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
      ||e.target.value===' '||e.target.value==='') 
    {
      setFiltro({
        ...filtro,
        [e.target.name] : e.target.value
      })
    }        
  };

   const Buscar=()=>{  
    setContrato([])  
    llenaListBusq()    
   }

   const onChangeDate=(e)=>{
    if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined 
      
    ) {
      if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
      {        
        setFiltro({...filtro,[e.target.name] : e.target.value})                   
      }      
    }
    
  }

  const selectTipo= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
  <InputLabel id="demo-select-small">Tipo</InputLabel>
  <Select
    labelId="demo-select-small" id="demo-select-small" name="tipo"
    value={tipo} label="Tipo" onChange={onChange}
  >      
    {listTipo.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Alias}</MenuItem>)}
  </Select>
</FormControl>

  return (
  <Box style={{ padding:0,margin:0}} >
    <Card  >
      <CardContent sx={{paddingBottom:0,padding:0,alignContent:"center"}} id="micard">
        <Box display="flex" flexDirection="row" sx={{marginTop:.7+'rem'}}   >                                                                      
          <Box pr={1} >
          <Tooltip title="Buscar">
            <IconButton color="primary"  aria-label="add circle" size="small"
              component="span" onClick={Buscar}
            >
              <SearchIcon/>
            </IconButton>         
            </Tooltip>                                                                                                                     
          </Box>                               
          <Box >                 
                                                                                                                                                     
            <TextField
              id="date-Ini" label="Fecha Inicio" type="date" size='small'
              value={FIni} sx={{ width: 9+'rem',marginRight:.7+'rem' }} name="FIni"
              InputLabelProps={{ shrink: true }} onChange={onChangeDate}
            />
        
            <TextField
              id="date-Fin" label="Fecha Final" type="date" size='small'        
              value={FFin} sx={{ width:9+'rem',marginRight:.7+'rem'  }} name="FFin"
              InputLabelProps={{ shrink: true }} onChange={onChangeDate}
            />                        
                                                                                                                                                                                                                                         
          </Box>  
          {listTipo.length!==0 ? selectTipo  :null}                                                      
        </Box>                                                                                                                  
      </CardContent>                                
    </Card>
    <Grid direction="row" container  sx={{marginTop:.2+'rem'}}>
      <Grid sx={{marginRight:.5+'rem',width:'60%'}}>
        {  espera && datos.length ===0 ?
          <Box  sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
        : <TablaContrato datos={datos} setContrato={setContrato} Buscar={Buscar} />  }
      </Grid>
      <Grid sx={{width:'35%'}}>
        { esperaArch   ? <Box sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
        : contrato.length!==0?
          <TablaArchivos datos={listArchivos} contrato={contrato}  llenarListFile={llenarListFile} />            
          :null
        }
      </Grid>
    </Grid>
  </Box> 
  )
}

export default Contrato