import React,{useState,useEffect} from 'react';
import { makeStyles } from 'tss-react/mui';
import {Box , Button,Card,Typography, TextField} from '@mui/material';
import axios from 'axios';
import Progress from "../../Generales/Progress";
import { useNavigate } from "react-router-dom";
import FormDialog from '../../Layout/FormDialog'
import {toast} from 'react-toastify';
import {apiFDataHeader,ErrorGeneral } from '../../funciones/ClienteAxios';
import { v4 as uuidv4 } from 'uuid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { printConsole } from '../../funciones/ConsolaLog';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      },         
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const ModalArchivo = ({modalAbierto,setModalAbierto,titulo,registro,llenarListFile}) => {
    const history = useNavigate();
    const classes = useStyles();
    const source = axios.CancelToken.source();      
    const idusu= localStorage.getItem('UsuId');    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const [espera,setEspera]=useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');  
    const [aviso, setAviso] = useState(false);      
    const [arraySubidos,setArraySubidos] = useState([])
    const [arrayPreview,setArrayPreview] = useState([])        
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [nombreArchivo, setNombreArchivo] = useState("")
    const [descripcion, setDescripcion] = useState("")
    
    useEffect(()=>{             

        return ()=> {
            source.cancel();
        }     
        
    },[]);

    
    const llenarListEvid = () => {               
        let bodyFormData = new FormData();
        bodyFormData.append('idDispositivo',auxDispV)
        bodyFormData.append('Llave',registro.Id)
        bodyFormData.append('Usuario',idusu)
        bodyFormData.append('Descr',descripcion)
        
        let auxSize=0
        arraySubidos.forEach((img)=>{
            auxSize=auxSize+img.size
            bodyFormData.append('multi-files',img)
        })              

        const headers = {
            "size":auxSize, "tipo":"SPACE",
            "clase":registro.TipoId ===1 ?"CONTRATO-MUSIC":"CONTRATO ",
            "llave":registro.Id,
        } 
        let url = "contrato-envio-file";
        function respuesta(auxiliar) 
        {
            if (auxiliar.success===true) 
            {
                setProgresoEnvio(0)
                let mensaje="Se envio correctamente el archivo "+nombreArchivo.corto
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                setAviso(true)     
                toast.success(`${mensaje}`,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `exito-env`
                })  
                llenarListFile()
                setModalAbierto(false)                        
            }       
        }    
        apiFDataHeader(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,headers,setProgresoEnvio);
    }  

    function reemplazo(cadena) {
        let nvo=cadena
        const exPermitidaNombrePubli = new RegExp('[$@#&%+()/:;!?,<>=]')
        const prb = "[$@#&+()/%:;!?,<>=]"
        for (let index = 0; index < prb.length; index++) 
        {
            const element = prb[index];
            while (nvo.indexOf(element)!== -1) 
            {   nvo = nvo.replace(exPermitidaNombrePubli, "")                                         
            }                    
        }
        while (nvo.indexOf(" ")!== -1) 
        {   nvo = nvo.replace(" ", "")                                         
        }   
        return(nvo)
    }
    

    function onChangeArchivos2(event){
        setArrayPreview([])
        setArraySubidos([])
        setNombreArchivo("")
        const regex = /([a-zA-Z0-9\s_\.\-\(\):])+(.pdf|.doc|docx|jpg|jpeg|gif|png|svg|webp|bmp)$/
        let arrArch = [] ,arrArch2 = []
        let arrPrev=[]
        let valido = true;
        let limite=event.target.files.length <= 3 ? event.target.files.length :3 
        for (let i =0;i <limite ;i++){
            let img = event.target.files[i]
            let auxCode = uuidv4();
            let nom ="";                
            if(img.name.length>=25)
            {   nom =  img.name.substring(0,24)+"..."+img.name.substring(img.name.lastIndexOf("."),img.name.length);
            }
            else
            {   nom = img.name;
            }
            if(img && img.name.toLowerCase().match(regex))
            {   //let auxNombre = auxCode + img.name.slice(img.name.lastIndexOf("."), img.name.length);
                let auxNombre =reemplazo(img.name);                
               arrArch.push(img)
               let myNewFile = new File([img], auxNombre, { type: img.type });   
               arrArch2.push(myNewFile)
               arrPrev.push(URL.createObjectURL(img))
               setNombreArchivo({corto:auxNombre,completo:img.name})
            }
            else
            {   
                let mensaje=`Formato de archivo incorrecto en ${nom} seleccione un tipo pdf, doc o docx.`
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel`
                  }); 
                  valido=false;
                  setArraySubidos([])
                  break;
            }
        }
        if(valido)
        {   setArraySubidos(arrArch2)
            setArrayPreview(arrPrev)
        }
    }    
  
    const onChange=(e)=>{    
        setDescripcion(e.target.value)     
    }

    function borrarArchivo(){
        setArrayPreview([]) 
        setNombreArchivo("")
    }

    const guardarDatos = () => 
    {   if (arraySubidos.length!==0) 
        {  
            llenarListEvid();    
        }   
        else{
            let mensaje=`Debe seleccionar un archivo para subir`
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            }); 
        }    
    };
    let accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    accept=accept+",image/jpg,image/jpeg,image/gif,image/png,image/svg,image/webp,image/bmp"
  return (
    <FormDialog
        titulo={`Agregar Archivo al Contrato ${registro.Id}`} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        tamanio="md" btnDeshabilitado={false} cargando={espera}
        setCargando={setEspera} btnTitulo={"Enviar"}
    > 
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:33+'rem'}} className={classes.root}>         
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop:'.3em',marginRight:'.7em'}}>
                <input type="file" name="file" onChange={onChangeArchivos2} hidden id={`cargarArchivo`}
                    accept={accept}  />
                <label htmlFor={`cargarArchivo`}>
                    <Button color="primary" variant="contained" size="small" component="span" 
                        sx={{fontSize:12 , width:"13rem"}}
                    >
                        Seleccionar Documento
                    </Button>
                </label>
                <TextField
                    label="Descripción" id="descripcion-campo"
                    value={descripcion} name="descripcion" onChange={onChange}
                    size="small" sx={{width:"17rem", marginLeft:.5+'rem'}} 
                />
            </Box> 
            {nombreArchivo.corto ?
            <Box>
              <Card  style={{marginRight:.5+'rem',paddingLeft:.3+'rem',
                paddingRight:.3+'rem',marginTop:.3+'rem',marginBottom:.3+'rem'}}        
              >    
                <Box display={"flex"} flexDirection="row" >                   
                  <Typography color={"primary"}> {nombreArchivo.corto} </Typography>  
                  <HighlightOffIcon size="small" color={"primary"} onClick={()=>borrarArchivo()} 
                    style={{marginLeft:.5+'rem',paddingBottom:.1+'rem',paddingTop:.1+'rem'}}  />   
                 </Box>                          
              </Card>
              </Box>
            :null}   
            {progresoEnvio>0 ? (
                <Progress progress={progresoEnvio}/>)
            :null}
        </Box>     
      </Box>
    </form>
    </FormDialog>
  )
}

export default ModalArchivo