import React,{useState,useEffect} from 'react';
import { makeStyles } from 'tss-react/mui';
import {Box , Stack,Card,Typography, TextField, IconButton, Tooltip,Grid} from '@mui/material';
import axios from 'axios';
import Progress from "../../Generales/Progress";
import { useNavigate } from "react-router-dom";
import FormDialog from '../../Layout/FormDialog'
import {toast} from 'react-toastify';
import {apiFDataHeader } from '../../funciones/ClienteAxios';
import { v4 as uuidv4 } from 'uuid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { printConsole } from '../../funciones/ConsolaLog';
import moment from 'moment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AudioFileIcon from '@mui/icons-material/AudioFile';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      },         
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const ModalListArchivo = ({modalAbierto,setModalAbierto,registro,listArchTema,llenarListFile}) => {
    const history = useNavigate();
    const classes = useStyles();
    const source = axios.CancelToken.source();      
    const idusu= localStorage.getItem('UsuId');    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const [espera,setEspera]=useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');  
    const [aviso, setAviso] = useState(false);      
    const [arraySubidos,setArraySubidos] = useState([])
    const [arrayPreview,setArrayPreview] = useState([])        
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [nombreArchivo, setNombreArchivo] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const listTipoAudio=[{tipo:"audio/mp4"} ,{tipo:"audio/mpeg"} ,{tipo: "audio/wav"},{tipo: "audio/ogg"},{tipo: "audio/mp3"} ,{tipo: `audio/x-m4a`}]
    useEffect(()=>{             

        return ()=> {
            source.cancel();
        }     
        
    },[]);

    
    const llenarListEvid = ( ) => {     
        let auxSize=0
        let auxNom="",lista=[]
        let bodyFormData = new FormData();
        arraySubidos.forEach((img)=>{
            auxSize=auxSize+img.archivo.size
            bodyFormData.append('multi-files',img.archivo)
            auxNom=img.archivo.name
            const filtrado=listArchTema.filter((tema)=>tema.Id===img.Id)
            
            lista.push({"id":filtrado[0].Id, "pista":filtrado[0].Pista, "nombre":auxNom, "alias":img.alias})
        })              
       // const lista=[{"id":14, "pista":6, "nombre":auxNom, "alias":}]
        const headers = {
            "size":auxSize, "tipo":"SPACE",
            "clase": "ALBUM-AUDIO",
            "llave":registro.Id,
        }           
        
        bodyFormData.append('idDispositivo',auxDispV)
        bodyFormData.append('Llave',registro.Id)
        bodyFormData.append('Usuario',idusu)
        bodyFormData.append('Lista',JSON.stringify(lista))
        
        let url = "album-tema-envio-file";
        function respuesta(auxiliar) 
        {
            if (auxiliar.success===true) 
            {    console.log(auxiliar);
                setProgresoEnvio(0)
                let mensaje="Se envio correctamente el archivo "
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                setAviso(true)   
                llenarListFile(2)  
                auxiliar.archivos.forEach(element => {
                    toast.success(`${mensaje} ${element.alias}`,{
                        pauseOnFocusLoss: false,
                        theme: "colored",
                        toastId: `exito-env${element.keyId}`
                    })                      
                });
                
                setModalAbierto(false)                        
            }       
        }    
        apiFDataHeader(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,headers,setProgresoEnvio);
    }  

    function reemplazo(cadena) {
        let nvo=cadena
        const exPermitidaNombrePubli = new RegExp('[$@#&%+()/:;!?,<>=]')
        const prb = "[$@#&+()/%:;!?,<>=]"
        for (let index = 0; index < prb.length; index++) 
        {
            const element = prb[index];
            while (nvo.indexOf(element)!== -1) 
            {   nvo = nvo.replace(exPermitidaNombrePubli, "")                                         
            }                    
        }
        while (nvo.indexOf(" ")!== -1) 
        {   nvo = nvo.replace(" ", "")                                         
        }   
        return(nvo)
    }
    
    function ErrorGeneral(error,historial) {
        console.log(error);   
        toast.error(`Ha ocurrido un error, vuelve a intentarlo mas tarde.`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `mensaj-err1`
        })           
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        { console.log('datos incorrectos' );  
        }
        else
        {
          if ( ( error && error.response && error.response.data && error.response.data?.error?.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data?.error?.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                            
            historial("/");                              
          }
          else{
            if ( ( error && error.response && error.response.data && error.response.data.mensaje==="No se agrego el Token en los Headers de la petición." )) 
            {
              console.log('loguearse de nuevo');
              localStorage.clear();                            
              historial("/");                              
            }
          } 
        }      
    }

      
    function onChangeArchivos2(event){
        setArrayPreview([])
        setArraySubidos([])        
        const regex = /([a-zA-Z0-9\s_\.\-\(\):])+(.mp3|.aac|.ogg|.m4a|.wav)$/
        let arrArch2 = [], auxId=0, arrPrev=[], valido = true;
        let limite=event.target.files.length <= 3 ? event.target.files.length :3 
        for (let i =0;i <limite ;i++){
            let img = event.target.files[i]
            let auxCode = uuidv4();
            let nom ="";                
            if(img.name.length>=30)
            {   nom =  img.name.substring(0,30)+"..."+img.name.substring(img.name.lastIndexOf("."),img.name.length);
            }
            else
            {   nom = img.name;
            }
            if(img && img.name.toLowerCase().match(regex))
            {   let auxNombre = auxCode + img.name.slice(img.name.lastIndexOf("."), img.name.length);
                //let auxNombre =reemplazo(img.name);                                       
                let myNewFile = new File([img], auxNombre, { type: img.type });   
                auxId=parseInt(event.target.name.substring(4,event.target.name.length))
                arrArch2={archivo:myNewFile, Id:auxId, alias:img.name}
                arrPrev.push(URL.createObjectURL(img))               
            }
            else
            {   
                let mensaje=`Formato de archivo incorrecto en ${nom} seleccione un tipo mp3, aac, ogg, m4a, wav.`
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel`
                  }); 
                valido=false;
                //setArraySubidos([])
                break;
            }
        }
        if(valido)
        {   let filtro= arraySubidos.findIndex((tema)=>tema.Id === auxId)            
            let mod=arraySubidos
            if (filtro=== -1) 
            {   mod.push(arrArch2)                
            }
            else
            {   mod.splice(filtro,1,arrArch2)                 
            }                        
            setArraySubidos(mod)
            setArrayPreview(arrPrev)
        }
    }    
  
    const onChange=(e)=>{    
        setDescripcion(e.target.value)     
    }

    function borrarArchivo(auxId){
        setArrayPreview([])      
        let filtro= arraySubidos.findIndex((tema)=>tema.Id === auxId)            
        let mod=arraySubidos   
        if (filtro!== -1) 
        {   
            mod.splice(filtro,1)                 
        }
        setArraySubidos(mod)
    }

    const guardarDatos = () => 
    {   if (arraySubidos.length!==0) 
        {  
            llenarListEvid();    
        }   
        else{
            let mensaje=`Debe seleccionar un archivo para subir`
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            }); 
        }    
    };
    const accept="audio/mp3,audio/aac,audio/ogg,audio/m4a,audio/wav"    

    const reproductor=(archivo,ancho)=>{
        return(
        <audio controls autoplay id="miAudio" style={{height:1.5+'rem',width:ancho}} >
            {listTipoAudio.map((formato)=>(
                <source src={archivo} type={ formato.tipo} />     
            ))}                                
        </audio>
        )
    }

  return (
    <FormDialog
        titulo={`Subir Pistas a ${registro.Titulo}`} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        tamanio="md" btnDeshabilitado={false} cargando={espera}
        setCargando={setEspera} btnTitulo={"Enviar"}
    > 
        
        <Grid container alignItems={'center'} justifyContent={arraySubidos.length !== 0 ? 'center' : 'start'} spacing={1} >                            
        {listArchTema.map((tema)=>(
            <Grid item xs={12} >
            <Card  > 
                <Grid  container alignItems={'center'} justifyContent={arraySubidos.length !== 0 ? 'start' : 'center'}>               
                    <Grid  item textAlign={'center'} xs={2}  sx={{paddingTop: '1rem'}} >
                        <input type="file" name={`file${tema.Id}`} onChange={onChangeArchivos2} 
                            hidden id={`cargarArchivo${tema.Id}`} accept={accept}  />
                        <label htmlFor={`cargarArchivo${tema.Id}`} >   
                            <Tooltip title="Cargar archivo">
                                <CloudUploadIcon sx={{ fontSize: 40 ,margin:.5+'rem'}}
                                    color={tema.Archivo.length!==0?"primary":"inherit" }  />                                   
                            </Tooltip>                                       
                        </label>
                    </Grid>
                    <Grid item xs={arraySubidos.length !== 0 ? 6 : 6} >
                        <section> 
                            <Typography  id={`descripcion-campo${tema.Id}`}                    
                                sx={{width:"17rem", marginLeft:.5+'rem'}}                         
                            >
                                {`${tema.Pista} - ${tema.Titulo}`}
                            </Typography>                
                            {tema.Archivo.length!==0? 
                                <span>
                                    <Box sx={{ display: {   xs: 'block' , sm: 'none'} }} >
                                        {reproductor(tema.Archivo,15+'rem')}
                                    </Box>
                                    <Box  sx={{ display: {   xs: 'none' , sm: 'block'} }} >
                                        {reproductor(tema.Archivo,18+'rem')}                           
                                    </Box>        
                                </span>
                            :null}   
                        </section>                        
                    </Grid>
                    {arraySubidos.length!==0 ? 
                     <Grid item textAlign={'center'}>
                       { arraySubidos.map((archivo)=>{
                            if (archivo.Id===tema.Id) {
                                return(
                                <Stack justifyContent="center"  alignItems="center" direction={"row"} spacing={1}>
                                    {/* <AudioFileIcon fontSize={'large'}/> */}                                         
                                    <Typography color={"primary"} variant={'caption'}
                                        sx={{wordWrap: "break-word"}}
                                    >
                                        {archivo.alias.length > 30 ? archivo.alias.substring(0, 30).trim() + '...' : archivo.alias}                                        
                                    </Typography>
                                    <Tooltip title="Descartar archivo">
                                        <HighlightOffIcon size="small" color={"primary"} 
                                            onClick={()=>borrarArchivo(tema.Id)} />   
                                    </Tooltip>                     
                                </Stack>
                                )        
                            }                        
                        })}
                    </Grid>
                    : <Grid item xs={6} sx={{width:20+'rem'}}>  </Grid>
                    }
                </Grid>
            </Card> 
            </Grid>
        ))}            
        {progresoEnvio>0 ? (
            <Progress progress={progresoEnvio}/>)
        :null}         
    </Grid>
    
    </FormDialog>
  )
}

export default ModalListArchivo